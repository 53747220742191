import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { email, phoneValidator, required } from '@core/utils/validations/validations.js';
import { config } from '@fullcalendar/core';

export default {
  data() {
    return {


      //setting_auth: {},
      prefix_url: '',
      activation_token: undefined,
      //  verificationId: null,
      // phoneAuthHandler: undefined,
      prefixApi: '',
      phoneObject: {},
      disableVerfiyButton: false,
      captchaVerify: false,
      disableButton: false,
      submitProcessing: false,
      change_phone: false,
      tabIndex: 0,
      step_number: 0,
      required,
      email,
      phone_validate: phoneValidator,
    }
  },
  mounted() {


  },
  watch: {
    phoneObject: function (newVal, oldVal) {
      if (newVal && newVal.number) {
        this.formData.phone = newVal.number.replace(/\D/g, '');
        store.dispatch("setPhoneObject", newVal);
        this.checkStatusCountry(newVal.countryCode);
      }
      //   recaptcha

      // store.commit('auth/setPhoneData', newVal)
    },
    recaptchaWatch(newVal, oldVal) {

      this.formData.recaptcha = store.state.auth.recaptcha;
    },
    tabIndex(newVal, oldVal) {


      this.formData.authentcate_by = newVal == 0 ? 'phone' : 'email';

    },

  },
  computed: {
    authData() {
      return this.$store.state.auth.dataAuth;
      // return this.$store.getters['auth/phoneObject'];
    },
    setting_auth() {
      return this.$store.state.setting.setting_auth;
      // return this.$store.getters['auth/phoneObject'];
    },
    countres() {
      return this.$store.state.setting.countres_code ?? [];
      // return this.$store.getters['auth/phoneObject'];
    },
    countresConfig() {
      return this.$store.state.setting.countresConfig;
      // return this.$store.getters['auth/phoneObject'];
    },
    defaultCountry() {
      return this.$store.state.setting.defaultCountry;
      // return this.$store.getters['auth/phoneObject'];
    },
    telData() {
      return this.$store.state.auth.phoneObject;
      // return this.$store.getters['auth/phoneObject'];
    },
    recaptchaData() {
      return this.$store.state.auth.recaptcha;
    },
    isValidPhone() {

      return (this.formData && this.formData.authentcate_by === 'phone'
        && this.telData &&
        this.telData.valid == true) || (this.formData && this.formData.authentcate_by === 'email');

    },
    verify_sms_sa() {

      return (this.setting_auth && this.setting_auth.enabled_sms == true && this.phoneObject && this.phoneObject.countryCode == "SA") || (this.formData && this.formData.authentcate_by == 'email');

    },
    sms_provider() {

      if (this.setting_auth) {
        if (this.setting_auth.enabled_sms == true && this.phoneObject && this.phoneObject.countryCode == "SA") {
          return false;
        }
        if (this.setting_auth.enabled_sms_google == true) {
          return true;
        }

      }

      return false;
    },


  },
  created() {
    this.checkStatusCountry(this.defaultCountry);
  },
  methods: {

    checkStatusCountry(country_code = null) {


      if (country_code == null && this.phoneObject && this.phoneObject.countryCode) {
        country_code = this.phoneObject.countryCode;
      }

      country_code = country_code.toUpperCase();
      var configcountry = this.countresConfig.find(n => n.country_code == country_code);
      if (configcountry) {
        // this.setting_auth = configcountry;
        store.dispatch("setting/setSettingAuth", configcountry);
      }


      // return configcountry;
    },
    updateFormData(phoneObject) {
      // console.log('phoneObject',phoneObject)
      if (phoneObject.number) {
        this.phoneObject = phoneObject;
        this.formData.country_code = phoneObject.countryCode;
        this.formData.phone = phoneObject.number.replace(/\D/g, '');
        store.dispatch("setPhoneObject", phoneObject);
        this.checkStatusCountry(phoneObject.countryCode);
      } else if (phoneObject.iso2) {
        this.formData.country_code = phoneObject.iso2;
        this.formData.iso2 = phoneObject.iso2;
        this.checkStatusCountry(phoneObject.iso2);
      }

      //  this.formData.country_code=phoneObject.countryCode;

    },
    restAll(form_type) {

      this.formData = {
        authentcate_by: "phone",
        form_type: form_type,
        agree: false,
        phone: "",
        phone_number: "",
        email: '',
      }
      if (this.verifyForm) {
        this.verifyForm = {
          verification_code: '',
          activation_token: '',
        }
      }
      if (this.dataProfile) {
        this.dataProfile = {};
      }
      this.activation_token = undefined;
      this.phoneObject = {};
      this.disableVerfiyButton = false;
      this.captchaVerify = false;
      this.disableButton = false;
      this.submitProcessing = false;
      this.tabIndex = 0;
      this.step_number = 0;

    },

    getFormData(formData) {

      formData.nationalNumber = this.phoneObject ? this.phoneObject.nationalNumber : '';
      formData.iso2 = this.phoneObject ? this.phoneObject.iso2 : '';
      formData.country_code = this.phoneObject ? this.phoneObject.countryCallingCode : '';

      return formData;

    },
    validationUserData(configObject) {


      const { url, formData, success, error, callback } = configObject;


      //     let formData=this.formData;

      //      if(this.formData.authentcate_by=="phone"){ 
      //             formData=this.getFormData(formData); 
      //        }
      // if(data && data.phone){
      //     formData=data;
      //   }


      this.$http.post(`${this.prefixApi}/${url}`, formData)
        .then(response => {
          let data = response.data;
          let message = data.msg ? data.msg : data.message;
          //  this.submitProcessing = false
          if (response.data.status === true) {
            //  this.showToast(message, 'success')
            //  this.step_number=2;
            configObject.success({
              msg: message,
            });
            configObject.callback(data);


          } else {
            // this.showToast(message, 'danger') 
            configObject.error({ msg: message });
          }
        })
        .catch(error => {
          //  console.log('error',error)
          // this.submitProcessing = false

          configObject.error({
            msg: this.getErrorMsg(error),
            errors: this.errorsHandler(error)
          });
          // this.$router.push({ name: 'misc-error-404' })
        })

    },
    getErrorMsg(error) {
      if (!error.response) {
        return false;
      }

      let result = error.response;
      if (error.response && error.response.data) {
        result = error.response.data;
      }
      let msg = "";
      if (error.message) {
        msg = error.message;
      }
      if (result.msg && result.msg.length > 0) {
        msg = result.msg;
      }
      return msg;
    },
    errorsHandler(error, formRef = "loginForm", return_error = false) {

      if (!error.response) {
        return false;
      }

      let result = error.response;
      if (error.response && error.response.data) {
        result = error.response.data;
      }
      //  console.log('result',result)
      if (result.errors) {
        //  self.errorsres = error.errors;
        // console.log('error.errors',error.errors)
        if (this.$refs[formRef]) {
          this.$refs[formRef].setErrors(result.errors)
        }
        if (return_error == true) {
          return result.errors;
        }

      }
      let msg = "";
      if (error.message) {
        msg = error.message;
      }
      if (result.msg && result.msg.length > 0) {
        msg = result.msg;
      }
      this.showToast(msg, 'danger')




    },
    showToast(text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'CheckIcon',
          text,
          variant,
        },
      })
    },

  }
};
